#terms-of-use {
    
    .terms-container {
        max-width: 900px;
        margin-bottom: 75px;
        
        .last-updated-date {
            background-color: var(--tile-background);
            padding: 5px 10px;
            width: fit-content;
            border-radius: 10px;
            color: var(--text-color-secondary);

            strong {
                font-weight: 600;
            }
        }

        h2 {
            font-weight: 300;
            margin-bottom: 12px;
            margin-top: 35px;
        }

        h3 {
            font-weight: 600;
            margin-bottom: 12px;
        }

        a {
            color: var(--blocks-blue);
            text-decoration: none;
        }

        blockquote {
            margin-block-start: 0;
            margin-inline-start: 6px;
            margin-inline-end: 0px;
            border-left: 5px solid var(--text-color-main);
            padding-left: 15px;
        }

        p {
            margin-bottom: 12px;

            strong {
                font-weight: 600;
            }
        }

        li {
            margin-bottom: 6px;
        }
    }
}