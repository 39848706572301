.resource-sidebar {
    width: 280px;
    height: 550px;
    background-color: var(--container-bg-color);
    margin-bottom: 50px;
    border-radius: 25px;
    position: absolute;
    margin-left: -50px;

    .apps, .posts-n-resources {
        padding: 25px 0 25px 55px;
        display: flex;
        flex-direction: column;

        h4 {
            font-weight: 600;
            font-size: 12pt;
        }

        a {
            display: flex;
            align-items: center;
            font-size: 14pt;
            text-decoration: none;
            color: var(--text-color-main);
            margin-top: 25px;
            transition: color 0.3s ease;
            width: fit-content;

            svg {
                margin-right: 20px;
                font-size: 22pt;
                color: var(--blocks-blue);

                &.icon-adjust {
                    margin-bottom: 5px;
                }

                &.hsa-sidebar-icon {
                    font-size: 20pt;
                }

                &.calculators-sidebar-icon {
                    font-size: 26pt;
                    margin-left: -5px;
                    margin-right: 17px;
                }

                &.credit-sidebar-icon {
                    font-size: 20pt;
                }
            }

            body.hasHover &:hover {
                color: var(--blocks-blue);
            }

            &.active {
                color: var(--blocks-blue);
                font-weight: 500;
            }
        }

        .nav-item-with-badge {
            display: flex;
            align-items: center;

            #blocks-badge {
                margin-left: 10px;
                margin-top: 25px;
            }
        }
    }

    hr {
        width: 65%;
        margin-left: 55px;
        border: none;
        height: 2px;
        border-radius: 2px;
        background-color: var(--menu-item-inactive);
    }
}

@media only screen and (min-width: 1300px) {
    .resource-sidebar {
        margin-left: 0px;
        width: 275px;

        .apps, .posts-n-resources {
            padding: 25px 0 25px 30px;
        }

        hr {
            width: 70%;
            margin-left: 30px;
        }
    }
}

@media only screen and (max-width: 750px) {
    .resource-sidebar {
        width: 84px;
        height: 500px;
        background-color: var(--container-bg-color);
        margin-bottom: 50px;
        border-radius: 25px;
        position: absolute;
        margin-left: -50px;
        padding: 10px 0;
        transition: width 0.35s ease, padding 0.35s ease;

        .sidebar-mobile-toggle {
            padding-left: 45px;
            padding-top: 10px;
            color: var(--dark-mode-toggle-icons);
            display: flex;
            align-items: center;
            transition: color 0.3s ease;
            cursor: pointer;

            svg {
                font-size: 20pt;
                color: var(--dark-mode-toggle-icons);
                margin-right: 0px;
                transform: rotate(180deg);
                transition: transform 0.3s ease;
            }

            body.hasHover &:hover {
                color: var(--text-color-secondary);
            }
        }
    
        .apps, .posts-n-resources {
            padding: 10px 0 10px 45px;
            display: flex;
            flex-direction: column;
    
            h4 {
                font-weight: 600;
                font-size: 12pt;

                svg {
                    font-size: 20pt;
                    min-width: 26px;
                    min-height: 26px;

                    &#post-pin {
                        transform: scaleX(-1);
                    }
                }
            }
    
            a {
                display: flex;
                align-items: center;
                font-size: 12pt;
                text-decoration: none;
                color: var(--text-color-main);
                margin-top: 20px;
                transition: color 0.3s ease;
    
                svg {
                    margin-right: 0px;
                    font-size: 20pt;
                    min-width: 26px;
                    min-height: 26px;
                    color: var(--blocks-blue);
    
                    &.icon-adjust {
                        margin-bottom: 5px;
                    }
    
                    &.hsa-sidebar-icon {
                        font-size: 18pt;
                    }
    
                    &.calculators-sidebar-icon {
                        font-size: 24pt;
                        min-width: 32px;
                        min-height: 32px;
                        margin-left: -3px;
                        margin-right: 0px;
                    }
    
                    &.credit-sidebar-icon {
                        font-size: 18pt;
                    }
                }
    
                body.hasHover &:hover {
                    color: var(--blocks-blue);
                }
            }
    
            .nav-item-with-badge {
                display: flex;
                align-items: center;
    
                .new-badge {
                    background-color: var(--blocks-blue);
                    padding: 5px 10px;
                    font-size: 10pt;
                    border-radius: 10px;
                    font-weight: 500;
                    color: white;
                    margin-left: 10px;
                    cursor: default;
                    margin-top: 20px;
                }
            }
        }
    
        hr {
            width: 100%;
            margin-left: 0;
            background-color: var(--divider-light);
        }

        &.expanded {
            width: 250px;
            height: 500px;

            .sidebar-mobile-toggle {
                padding-left: 50px;
                padding-top: 10px;
   
                svg {
                    transform: rotate(0);
                    margin-right: 15px;
                }
            }
        
            .apps, .posts-n-resources {
                padding: 15px 0 15px 50px;

                h4 {
                    white-space: nowrap;
                }

                a {
                    white-space: nowrap;
        
                    svg {
                        margin-right: 15px;

                        &.calculators-sidebar-icon {
                            font-size: 24pt;
                            margin-left: -5px;
                            margin-right: 11px;
                        }
                    }
                }
            }

            hr {
                width: 65%;
                border: none;
                height: 2px;
                margin-left: 50px;
                border-radius: 2px;
                background-color: var(--menu-item-inactive);
            }
        }
    }
}