.about-heading {
    font-size: 60pt;
    color: var(--blocks-blue);
    font-weight: 600;
    padding-bottom: 30px;
    max-width: 900px;
    display: block;
    margin: 50px auto;
}

.our-why {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin: auto;

    p {
        font-size: 24pt;
        font-weight: 300;
        padding-bottom: 25px;
        z-index: 5;
    }

    img {
        width: 200px;
        align-self: center;
        margin: -20px 0;
        margin-top: -35px;

        &#zagg-arrow {
            align-self: flex-end;
            margin-right: 15%;
            margin-top: -45px;
        }

        &#burst {
            align-self: flex-start;
            margin: 0px;
            margin-top: -25px;
            margin-left: 5%;
            width: 175px;
            transform: rotate(-10deg);
        }
    }
}

.quote-wrapper {
    width: 100%;
    max-width: 900px;
    margin: 50px auto;
    display: flex;
    align-items: center;

    img {
        width: 100%;
        max-width: 500px;
        margin: auto;
        padding-right: 75px;
    }
}

@media only screen and (max-width: 525px) {
    .about-heading {
        font-size: 15vw;
        margin-bottom: 25px;
    }

    .our-why {
        p {
            font-size: 18pt;
        }
    }
}