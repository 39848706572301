html, body, button, h1, h2, h3, h4, h5, h6, p, textarea, div, span {
    font-family: 'Inter', Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body, html {
    // removal of mobile safari grey outline onClick
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    overflow-x: hidden;
}

.page-content {
  padding: 0 25px;
  padding-top: 75px;
  position: relative;
  // minus 392px calc = .page-content 75px top padding + footer height of 317px
  min-height: calc(100vh - 392px);
  margin: auto;
}

.twelvefiftycontent {
    max-width: 1250px;
    margin: auto;
}

.page-title {
    display: flex;
    align-items: center;
    color: var(--blocks-blue);
    font-weight: 600;
    font-size: 2em;
    padding-top: 12px;

    svg {
        margin-right: 12px;
        color: var(--blocks-blue);
        font-size: 30pt;
        // margin-top: 2px;
    }
}

@media only screen and (max-width: 750px) {
    .page-content {
        padding: 0 15px;
        padding-top: 75px;
    }
}

@media only screen and (max-width: 600px) {
    .page-content {
        // minus 570px calc = .page-content 75px top padding + footer height of 495px
        min-height: calc(100vh - 570px);
    }
}

input, textarea, select {
    border: 0px;
    padding: 0px 16px;
    font-family: 'Inter', sans-serif;
    background-color: var(--container-bg-color);
    border-radius: 15px;
    height: 50px;
    font-size: 14pt;
    -webkit-appearance: initial;
    outline: none;
    outline: 0px solid transparent;
    // transition: outline 0.2s ease;

    &:focus {
        outline: 2px solid var(--blocks-blue);
    }
}

button {
    font-family: 'Inter', sans-serif;
    height: 50px;
    padding: 0px 25px;
    font-size: 14pt;
    border: none;
    border-radius: 15px;
}

textarea {
    padding: 16px 16px;
}

textarea {
  resize: none;
}

button:enabled {
    background-color: var(--blocks-blue);
    color: var(--button-text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;

    body.hasHover &:hover {
        background-color: var(--blocks-button-hover);
    }
}

button:disabled {
    background-color: var(--container-bg-color);
    color: var(--text-color-secondary);
}

// Global 
@import "./DarkMode.scss";
@import './shoebox-light.css';
@import './shoebox-dark.css';
@import "./Components/Global/Navigation/Navigation.scss";
@import "./Components/Global/Header/Header.scss";
@import "./Components/Global/Footer/Footer.scss";
@import "./Components/Global/Badges/Badge.scss";

// Support
@import "./Components/Global/Support/About.scss";
@import "./Components/Global/Support/Feedback.scss";
@import "./Components/Global/Support/TermsOfUse.scss";
@import "./Components/Global/Support/PageNotFound.scss";
@import "./Components/Global/Support/PrivacyPolicy.scss";
@import "./Components/Global/Support/Subscription.scss";

// Landing
@import "./Components/Landing/Landing.scss";
@import "./Components/Landing/AppGallery.scss";

// Resources 
@import "./Components/Resources/Resources.scss";
@import "./Components/Resources/Components/Sidebar.scss";
@import "./Components/Resources/Topic.scss";
@import "./Components/Resources/Roadmap.scss";

    // Apps
    @import "./Components/Resources/Apps/AppCategory.scss";
    @import "./Components/Resources/Apps/AppCard.scss";

    // Posts
    @import "./Components/Resources/Posts/Post.scss";
    @import "./Components/Resources/Posts/Components/TagsBar.scss";

// Announcements
@import "./Components/Announcements/Announcements.scss";

// Shoelace Overrides

/* RADIO GROUP */

sl-radio-button::part(base) {
    height: 50px;
}

sl-radio-group::part(form-control-label) {
    font-weight: 500;
    font-size: 14pt;
    margin-bottom: 10px;
    color: var(--sl-color-black);
}

sl-radio-button::part(button) {
    border-radius: 10px;
    border: none;
    height: 36px;
    color: var(--sl-color-black);
    font-weight: 400;
    transition: background-color 0.4s ease;
    width: max-content;
    min-width: 100px;
}

sl-radio-button::part(button--checked) {
    background-color: var(--sl-color-primary-500);
    color: white;
    font-weight: 500;
}

body.hasHover sl-radio-button::part(button):hover {
    background-color: transparent;
}

body.hasHover sl-radio-button::part(button--checked):hover {
    background-color: var(--sl-color-primary-500);
}

sl-radio-group::part(button-group) {
    background-color: var(--sl-color-info-500);
    border-radius: 15px;
    padding: 7px;
    height: 50px;
}

sl-radio-group::part(form-control-help-text) {
    margin-top: 10px;
}

/* SELECT */

sl-select::part(form-control-input) {
    height: 50px;
    border-radius: 15px;
}

sl-select::part(combobox) {
    font-size: 14pt;
    height: 50px;
    border-radius: 15px;
    background-color: var(--sl-color-info-500);
}

sl-select::part(form-control-label){
    font-weight: 500;
    font-size: 14pt;
    margin-bottom: 10px;
    color: var(--sl-color-black);
}

sl-option::part(label) {
    color: var(--sl-color-black);
}

sl-option, .option-current {
    background-color: transparent;
    transition: background-color 0.3s ease;

    body.hasHover &:hover {
        background-color: var(--tile-hover);
    }
}

sl-option::part(base), .option--current {
    background-color: transparent;
}

sl-option::part(checked-icon) {
    margin-right: 10px;
    color: var(--blocks-blue);
}