.page-not-found {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 50px 30px;
}

.error-icon {
    font-size: 48pt;
    color: var(--menu-item-inactive);
    display: flex;
    margin-top: 10px;
}

.error-num {
    font-size: 96pt;
    font-weight: 600;
    line-height: 96pt;
}

.error-text {
    font-size: 16pt;
    font-weight: 300;
    font-style: italic;
    white-space: pre-line;
   

    .error-link {
        color: var(--blocks-blue);
        text-decoration: none;
    }
}