.tag-content {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;

    .post-tile {
        background-color: var(--container-bg-color);
        border-radius: 25px;
        padding: 15px;
        padding-right: 20px;
        max-width: 575px;
        height: auto;
        margin: 0 15px 15px 0;
        display: flex;
        align-items: center;

        h3 {
            font-weight: 600;
            font-size: 16pt;
        }
    
        h5 {
            font-weight: 300;
            margin-bottom: 10px;
        }
    }
    
    .fade-in-bottom {
        animation: fade-in-bottom 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        --index: 0;
        animation-delay: calc(0.1s * var(--index));
    }
    
    @keyframes fade-in-bottom {
        0% {
            -webkit-transform: translateY(50px);
                    transform: translateY(50px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateY(0);
                    transform: translateY(0);
            opacity: 1;
        }
    }
      
    
    .image-container {
        height: 100%;
    
        .post-image {
            height: 100%;
            width: 140px;
            object-fit: cover;
            border-radius: 15px;
            margin-right: 20px;
        }
    }
    
    .read-more {
        color: var(--blocks-blue);
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
        transition: color 0.4s ease;
        text-decoration: none;
        width: fit-content;
    
        svg {
            font-size: 14pt;
            transform: translate(0);
            transition: transform 0.4s ease;
        }
    
        &:hover {
            color: var(--blocks-button-hover);
    
            svg {
                transform: translate(5px);
            }
        }
    }
    
    .no-posts {
        display: flex;
        width: 100%;
        justify-content: center;
        color: var(--text-color-secondary);
        margin-top: 50px;
    }
}

@media only screen and (max-width: 625px) {
    .tag-content {
        .post-tile {
            margin-right: 5px;
            margin-bottom: 12px;

            .post-image {
                display: none;
            }
        }
    } 
}

// *!!* Also affects styles for same back control on AppCategory page
.back-to-resources {
    padding: 9px 18px;
    border-radius: 25px;
    background-color: var(--container-bg-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    color: var(--text-color-main);
    width: fit-content;
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px transparent;
    transition: box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
    
    svg {
        margin-right: 5px;
        font-size: 16pt;
    }

    body.hasHover &:hover {
        transform: scale(1.03);
        box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.10);
        background-color: var(--background-color);
    }
}