.home-fold {
    height: 100vh;
    width: 100vw;
    margin-left: -25px;
    margin-top: -75px;
    position: relative;

    svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
    
    .fold-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: fit-content;
        margin: auto;
        height: calc(100% - 5vh);
        z-index: 100;

        .title-tagline {
            z-index: 100;

            h1 {
                font-size: min(60pt, 9vw);
                font-weight: 500;
                color: #1084ffc4;
                margin-bottom: 30px;
            }

            span {
                font-size: 20pt;
                font-weight: 200;
                color: var(--button-text-color);
                font-style: italic;
            }
        }

        .fold-navigation {
            display: flex;

            z-index: 100;

            button {
                height: 55px;
                border-radius: 15px;
                font-size: 14pt;
                font-family: 'Inter', sans-serif;
                font-weight: 500;
                color: #1084ffc4;
                background-color: #ffffff54;
                margin-right: 12px;
                transition: background-color 0.4s ease;
                -webkit-box-shadow: 0px 10px 69px -12px #cae1fe;
                -moz-box-shadow: 0px 10px 69px -12px #cae1fe;
                box-shadow: 0px 10px 69px -12px #cae1fe;
                cursor: pointer;

                body.hasHover &:hover {
                    background-color: #ffffff9d;
                }
            }
        }
    }

    .see-more {
        width: 30%;
        text-align: center;
        height: 80%;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .bg-blocks-logo {
            filter: invert(99%) sepia(4%) saturate(665%) hue-rotate(209deg) brightness(116%) contrast(100%);
            opacity: 30%;
            position: absolute;
            width: 50vw;
            max-width: 675px;
            right: 5vw;
            top: 15vh;
        }

        a {
            text-decoration: none;
            z-index: 100;
        }

        span {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: var(--button-text-color);
            cursor: pointer;

            svg {
                margin-top: 5px;
                font-size: 16pt;
                transform: translateY(0px);
                transition: transform 0.4s ease;
            }

            body.hasHover &:hover {
                svg {
                    transform: translateY(7px);
                }
            }
        }
    }
}

@media only screen and (max-width: 750px) {
    .home-fold {
        flex-direction: column;
        justify-content: center;
        margin-left: -15px;

        .fold-content {
            margin-bottom: 15vh;

            .title-tagline {
                margin-top: 0;
            }

            .fold-navigation {
                .fold-get-started {
                    margin-right: 120px;
                }
            }
        }

        .see-more {
            height: 125px;

            .bg-blocks-logo {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .home-fold {
        .fold-content {
            .title-tagline {
                z-index: 100;
    
                h1 {
                    font-size: min(60pt, 9vw);
                    font-weight: 500;
                    color: #1084ffc4;
                    margin-bottom: 30px;
                }
            }

            .fold-navigation {
                flex-direction: column;
                width: 100%;
                max-width: 225px;
                align-items: center;
                margin: 0 auto;

                button {
                    margin-right: 0;
                    margin-bottom: 12px;
                    width: 100%;
                    height: 50px;
                }
            }
        }

        .see-more {
            width: calc(100% - 70px);
            height: 100px;
            margin-bottom: 50px;
        }
    }
}

.blocks-intro {
    height: fit-content;
    width: calc(100vw - 30px);
    max-width: 1250px;
    margin: auto;
    // margin-left: -25px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 22pt;
    font-weight: 600;
    color: var(--blocks-blue);

    img {
        max-height: 800px;
        max-width: 70%;
        margin-left: -200px;
    }

    .feature-copy {
        margin-bottom: 50px;
        margin-left: 30px;
        span {
            color: var(--text-color-secondary);
            font-size: 14pt;
            font-weight: 400;
        }

        p {
            color: var(--text-color-main);
            font-size: 18pt;
            font-weight: 400;
            max-width: 350px;
        }

        h3 {
            font-size: 40pt;
            color: var(--shoebox-yellow);
        }

        a {
            text-decoration: none;
            color: var(--blocks-blue);
            cursor: pointer;
        }

        sl-button.sbx-sign-up-btn::part(base) {
            width: 125px;
        }

        sl-button.sbx-sign-up-btn::part(label) {
            display: flex;
            align-items: center;
        }

        sl-button.sbx-sign-up-btn {
            margin-top: 25px;
            cursor: pointer;
            
            svg {
                font-size: 16pt;
                margin-left: 4px;
                transition: margin 0.3s ease;
            }

            body.hasHover &:hover {
                svg {
                    font-size: 16pt;
                    margin-left: 8px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .blocks-intro {

        img {
            margin-left: -200px;
        }

        .feature-copy {
            margin-bottom: 50px;
            margin-left: 15px;
        }
    }
}

@media only screen and (max-width: 750px) {
    .blocks-intro {
        margin: 50px 0;
        justify-content: center;

        img {
            max-height: 600px;
            margin-left: 0;
        }

        .feature-copy {
            max-width: 300px;
            margin-bottom: 50px;
            margin-left: -150px;
            padding: 25px;
            border-radius: 25px;
            background-color: #ffffff9c;
            backdrop-filter: blur(10px);
        }
    }
}

@media only screen and (max-width: 450px) {
    .blocks-intro {
        margin: 50px 0;
        justify-content: center;
        position: relative;
        height: 600px;

        img {
            max-width: 100vw;
            max-height: 700px;
            position: absolute;
        }

        .feature-copy {
            max-width: 300px;
            margin-bottom: 0px;
            margin-left: 0px;
            padding: 25px;
            border-radius: 25px;
            background-color: #ffffff9c;
            backdrop-filter: blur(10px);
        }
    }
}