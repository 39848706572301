.blocks-header {
    background-color: transparent;
    backdrop-filter: blur(5px);
    position: fixed;
    z-index: 500;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;


    .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100vw - 50px);
        height: 65px;
        padding: 0 25px;
        max-width: 1250px;
        margin: auto;
    }

    .blocks-brand {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding-bottom: 2px;

        img {
            height: 50px;
            width: 50px;
            margin-right: 10px;
        }

        h2 {
            font-weight: 500;
        }
    }
  
    h2 {
        color: var(--text-color-main);
    }
  
}

@media only screen and (max-width: 750px) {
    .blocks-header {
        .header-wrapper {
            padding: 0 15px;
            width: calc(100vw - 30px);
        }
    }
}