.privacy-policy {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    h1 {
        margin-bottom: 25px;
    }

    .policy-container {
        margin-bottom: 50px;

        img {
            height: 70px;
            width: 70px;
            border-radius: 15px;
        }

        h2 {
            color: var(--text-color-secondary);
            font-weight: 500;
            margin-top: 15px;
        }
    }

    p {
        margin-top: 18px;
        font-size: 14pt;
        text-align: justify;
        max-width: 700px;
    }

    a {
        color: var(--blocks-blue);
        text-decoration: none;
    }
}