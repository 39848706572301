#announcements {
    .page-title {
        svg {
            transform: rotate(-15deg);
        }
    }

    .no-found-text {
        margin-top: 35px;
        color: var(--text-color-secondary);
        animation: fade-in-text 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation-delay: 0.5s;

        @keyframes fade-in-text {
            0% {
                transform: translateY(10px);
                will-change: filter;
                filter: blur(5px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                will-change: filter;
                filter: blur(0px);
                opacity: 1;
            }
        }
    }

    .announcements-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(400px, 1fr));
        column-gap: 35px;
        row-gap: 35px;
        margin-top: 35px;
        margin-bottom: 100px;
        
        .announcement-card {
            background-color: white;
            transform: scale(1);
            box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.12);
            padding: 25px;
            border-radius: 25px;
            width: calc(100% - 50px);
            display: flex;
            flex-direction: column;
            transition: all 0.5s ease;
            cursor: pointer;

            .tag {
                font-weight: 600;
                color: var(--feature-badge);

                &.sign-up {
                    color: var(--budgeting-green);
                }
            }

            h2 {
                margin-top: 5px;
                font-size: 20pt;
                font-weight: 600;
            }

            .date {
                font-weight: 500;
                margin-bottom: 10px;
            }

            p {
                font-size: 14pt;
                color: var(--text-color-secondary);
                font-weight: 300;
            }

            .read-more {
                display: flex;
                align-items: center;
                font-weight: 500;
                color: var(--text-color-secondary);
                margin-top: 10px;
                margin-left: 0;
                transition: margin 0.5s ease;

                svg {
                    font-size: 20pt;
                    margin-left: 0px;
                    transition: margin 0.5s ease;
                }

                body.hasHover &:hover {
                    margin-left: 10px;
                }
            }

            body.hasHover &:hover {
                transform: scale(1.01);
                box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.20);

                .read-more {
                    margin-left: 5px;
                    color: var(--blocks-blue);

                    svg {
                        margin-left: 4px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .announcements-container {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 20px;
            max-width: 600px;
            margin: 0 auto;
            margin-top: 35px;
            margin-bottom: 100px;
        }
    }

    .single-announcement-page {
        max-width: 700px;
        margin: 35px auto;
        margin-bottom: 65px;

        .back-to-announcements {
            padding: 9px 18px;
            border-radius: 25px;
            background-color: var(--container-bg-color);
            text-decoration: none;
            display: flex;
            align-items: center;
            color: var(--text-color-main);
            width: fit-content;
            transform: scale(1);
            box-shadow: 0px 0px 0px 0px transparent;
            cursor: pointer;
            transition: box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
            
            svg {
                margin-right: 5px;
                font-size: 16pt;
            }
        
            body.hasHover &:hover {
                transform: scale(1.03);
                box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.10);
                background-color: var(--background-color);
            }
        }
    }

    .single-announcement-content {
        width: 100%;
        margin-top: 25px;

        img {
            width: 100%;
            max-width: 150px;
            margin-bottom: 15px;
        }

        .tag {
            display: block;
            font-weight: 600;
            color: var(--feature-badge);

            &.sign-up {
                color: var(--budgeting-green);
            }
        }

        h1 {
            font-size: 28pt;
            font-weight: 600;
        }

        h2 {
            font-size: 12pt;
            color: var(--text-color-secondary);
            font-weight: 500;
            margin-bottom: 25px;
            margin-top: 5px;
        }

        p {
            margin-top: 10px;
            font-size: 14pt;
        }

        a {
            text-decoration: none;
            color: var(--blocks-blue);
        }
    }
}