:root {
    --background-color: #ffffff;
    --background-color-transparent: #ffffff00;
    --blocks-blue: #1082FF;
    --blocks-button-hover: #0076f5;
    --button-text-color: #ffffff;
    --button-translucent-bg: #ffffff30;
    --text-color-main: #000000;
    --text-color-secondary: #7f7f7f;
    --text-color-tertiary: #c2c2c2;
    --container-bg-color: #f3f3f3;
    --chart-neutral-color: #D8D8D8;
    --menu-drop-shadow: #00000025;
    --menu-drop-shadow-hover: #4e4e4e24;
    --menu-item-inactive: #cecece;
    --divider-light: #dcdcdc;
    --modal-overlay-color: #e4e4e463;
    --list-item-hover: #e6e6e6;
    --dark-mode-toggle-icons: #aaaaaa;

    //Topics
    --budgeting-green: #008E0B;
    --investing-grey: #ABABAB;
    --finance-orange: #ffaa00;
    --credit-blue: #407DFF;
    --retirement-red: #a10000;

    // Shoebox Specific
    --shoebox-yellow: #FFC832;
    --shoebox-yellow-tint: #ffd970;
    --shoebox-yellow-shade: #ffbe0d;
    --yellow-light-reversal: #FFC832;
    --yellow-dark-reversal: #131313;
    --stat-bg: #ffecd0;
    --blocks-bg: #dcf5ff;

    // Badges
    --coming-soon-badge: #D9D9D9;
    --feature-badge: #855AFF;

    // Roadmap
    --roadmap-future-pill: #E0E0E0;
    --roadmap-future-pill-content: #696969;
    --roadmap-add-idea-header: #c7c7c7;
}

[data-theme="dark"] {
    --background-color: #131313;
    --background-color-transparent: #13131300;
    --button-text-color: #131313;
    --text-color-main: #f8f8f8;
    --text-color-secondary: #aaaaaa;
    --container-bg-color: #252525;
    --chart-neutral-color: #414141;
    --menu-drop-shadow: #d1d1d125;
    --menu-drop-shadow-hover: #e9e9e924;
    --menu-item-inactive: #646464;
    --modal-overlay-color: #25252563;
    --list-item-hover: #202020;
    --dark-mode-toggle-icons: #1B1B1B;
    --yellow-light-reversal: #131313;
    --yellow-dark-reversal: #FFC832;
    --stat-bg: #515066;
    --blocks-bg: #222b30;
}

body {
    background-color: var(--background-color);
    color: var(--text-color-main);
}