.post-loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-post {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 75px;

    .post-header {
        h1 {
            font-size: 32pt;
            text-align: center;
        }
    
        .post-meta {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: 10px 0;
    
            div {
                display: flex;
                align-items: center;
    
                svg {
                    margin-right: 5px;
                    font-size: 16pt;

                    &.signature {
                        font-size: 20pt;
                    }
                }
            }

            #post-date {
                margin: 0 15px;
            }
        }
    }

    .post-featured-image {
        img {
            width: 100%;
            margin-top: 30px;
            border-radius: 15px;
        }
    }

    .post-body {
        margin-top: 40px;
        text-align: justify;

        p, h2, h3, h4, h5 {
            margin-bottom: 1.25rem;
        }

        h2 {
            font-size: 18pt;
            font-weight: 700;
        }

        h3 {
            font-size: 16pt;
            font-weight: 600;
        }

        h4 {
            font-size: 14pt;
            font-weight: 500;
        }

        h2, h3, h4, h5 {
            text-align: left;
        }

        ol, ul {
            padding-inline-start: 20px;

            li:not(:last-of-type) {
                padding-bottom: 15px;
            }
        }

        .wp-block-spacer {
            height: 25px !important;
        }

        .wp-block-table {
            margin-left: 0;
            margin-right: 0;
        }

        .wp-block-quote {
            border-left: 4px solid;
            margin-left: 0;
            padding-left: 2rem;
        }

        .is-style-rounded {
            margin: auto;

            img {
                border-radius: 50%;
            }
        }

        a {
            color: var(--text-color-main);
            transition: color 0.3s ease;

            &:hover {
                color: var(--text-color-secondary);
            }
        }

        table, th, td {
            border: 1px solid;
            border-collapse: collapse;
            padding: 10px;
        }

        th, td {
            text-align: left;
        }

        table {
            width: 100%;
        }

        figcaption {
            margin-top: 10px;
        }
    }


}

@media only screen and (max-width: 500px) {
    .blog-post {
        margin-top: 15px;
    
        .post-header {
            h1 {
                font-size: 24pt;
            }
        
            .post-meta {
                justify-content: space-between;

        
                div {
                    flex-direction: column;
                    text-align: center;
        
                    svg {
                        margin-right: 0;
                    }
                }
            }
        }

        .post-featured-image {
            img {
                margin-top: 10px;
            }
        }

        .post-body {
            margin-top: 20px;
        }
    }
}