#feedback {
    .feedback-intro, .feedback-copy {
        font-size: 16pt;
        // font-weight: 300;
        color: var(--text-color-secondary);
    }

    .feedback-intro {
        margin: 15px 0;
    }

    .feedback-form {
        margin-top: 25px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;

        input, select {
            margin-bottom: 15px;
            width: calc(100% - 40px);
            max-width: 400px;
        }

        select {
            width: 100%;   
            max-width: 432px;
        }

        select:invalid {
            color: var(--text-color-secondary);
        }

        select::after {
            position: absolute;
            padding-right: 16px;
        }

        #feedback-textbox {
            height: 100px !important;
            width: calc(100% - 40px) !important;
            margin-bottom: 15px;
            max-width: 400px !important;
        }

        button {
            width: 100%;
            max-width: 440px;
        }

        .submit-button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .feedback-submitted {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 30px);
        max-width: 440px;
        margin-top: 50px;
        background-color: var(--container-bg-color);
        border-radius: 15px;
        padding: 25px 15px;
        padding-bottom: 40px;

        svg {
            font-size: 32pt;
            color: var(--blocks-blue);
        }

        h2 {
            font-size: 24pt;
            font-weight: 300;
            text-align: center;
        }

        span {
            text-align: center;
            margin-top: 15px;

            span {
                color: var(--blocks-blue);
                cursor: pointer;
                transition: color 0.3s ease;

                body.hasHover &:hover {
                    color: var(--blocks-button-hover);
                }
            }
        } 
    }

    .fade-in-bottom, .fade-in-plane {
        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

    .fade-in-plane {
        animation-delay: 0.2s;
    }

    @keyframes fade-in-bottom {
        0% {
          -webkit-transform: translateY(50px);
                  transform: translateY(50px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      
}