.resource-showcase {
    height: fit-content;
    margin-left: -25px;
    width: calc(100% + 50px);
    padding: 150px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .showcase-image {
        width: 100%;
        max-width: 400px;
    }

    .showcase-text {
        width: calc(100% - 50px);
        text-align: center;
        margin: 20px 25px;
        margin-bottom: 45px;
        font-size: 36pt;
        font-weight: 300;
    }

}

@media only screen and (max-width: 750px) {
    .resource-showcase {
        .showcase-image {
            max-width: 300px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .resource-showcase {
        .showcase-image {
            max-width: 200px;
        }

        .showcase-text {
            font-size: 32pt;
        }
    }
}

@media only screen and (max-width: 400px) {
    .resource-showcase {
        .showcase-image {
            max-width: 200px;
        }

        .showcase-text {
            font-size: 24pt;
        }
    }
}