.roadmap-fold-hero {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 1;
}

@media only screen and (max-width: 700px) {
    .roadmap-fold-hero {
        width: 110vh;
        overflow: hidden;
    }
}

.roadmap-wrapper {
    min-height: 100vh;

    .twelvefiftycontent {

        .roadmap-fold-header {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 3;
            width: 100%;
            height: 85vh;
            max-width: 750px;
            margin: auto;
            margin-bottom: calc(15vh - 75px);
            animation: fade-roadmap-header 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-roadmap-header {
                0% {
                  -webkit-transform: translateY(30px);
                          transform: translateY(30px);
                  opacity: 0;
                }
                100% {
                  -webkit-transform: translateY(0);
                          transform: translateY(0);
                  opacity: 1;
                }
              }
            

            h1 {
                font-size: min(70pt, 10.7vw);
                color: var(--blocks-blue);
                font-weight: 600;
                margin-bottom: 15px;
            }

            p {
                font-size: min(20pt, 5.5vw);
                text-align: left;
                margin-bottom: 25px;
            }

            p:last-of-type {
                max-width: 700px;
            }

            .fold-navigation {
                button {
                    height: 55px;
                    border-radius: 15px;
                    font-size: 14pt;
                    font-family: 'Inter', sans-serif;
                    font-weight: 500;
                    color: #1084ffc4;
                    background-color: #ffffff54;
                    margin-right: 12px;
                    transition: background-color 0.4s ease;
                    -webkit-box-shadow: 0px 10px 69px -12px #cae1fe;
                    -moz-box-shadow: 0px 10px 69px -12px #cae1fe;
                    box-shadow: 0px 10px 69px -12px #cae1fe;
                    cursor: pointer;
    
                    body.hasHover &:hover {
                        background-color: #ffffff9d;
                    }
                }
            }

            @media only screen and (max-width: 450px) {

                h1 {
                    margin-bottom: 10px;
                }

                p {
                    font-size: min(20pt, 5.5vw);
                    text-align: left;
                    margin-bottom: 15px;
                }

                .fold-navigation {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    button {
                        margin: 0;
                        margin-bottom: 12px;
                        width: 275px;
                    }
                }

            }
        }

        .roadmap-timeline {
            position: relative;
            display: block;
            margin: auto;
            padding: 100px 0;

            .line-wrapper {
                width: 100%;
                max-width: 530px;
                margin: auto;
                position: relative;

                .timeline-pill {
                    padding: 12px;
                    padding-right: 35px;
                    border-radius: 50px;
                    background-color: var(--blocks-blue);
                    width: fit-content;
                    display: flex;
                    align-items: center;

                    .pill-dot {
                        height: 49px;
                        width: 49px;
                        background-color: var(--background-color);
                        border-radius: 50px;
                        margin-right: 12px;
                    }

                    .pill-body {
                        color: var(--button-text-color);

                        h2 {
                            font-weight: 500;
                        }

                        span {
                            font-weight: 300;
                        }
                    }

                    &.future {
                        background-color: var(--roadmap-future-pill);

                        .pill-dot {
                            background-color: var(--roadmap-future-pill-content);
                        }

                        .pill-body {
                            color: var(--roadmap-future-pill-content);
                        }
                    }
                }

                .timeline-section-items {
                    padding-left: 73px;

                    .section-item {
                        padding-top: 25px;

                        .item-header {
                            display: flex;
                            align-items: center;
                            margin-bottom: 5px;

                            h3 {
                                font-weight: 500;
                                font-size: 16pt;
                                margin-right: 10px;
                            }
                        }

                        div {
                            color: var(--text-color-secondary);
                        }
                    }

                    .section-item:last-of-type {
                        padding-bottom: 65px;
                    }

                    &.soon {

                        .section-item:last-of-type {
                            padding-bottom: 100px;
                        }
                    }

                    &.future {
                        .section-item {
                            .item-header {
                                h3 {
                                    color: var(--roadmap-future-pill-content);
                                }
                            }
                        }

                        .section-item:last-of-type {
                            padding-bottom: 0px;
                        }
                    }

                    &.idea-invitation {
                        .section-item {
                            .item-header {
                                h3 {
                                    color: var(--roadmap-add-idea-header);
                                }
                            }

                            div {
                                color: var(--text-color-main);
                            }

                            a {
                                display: block;
                                margin-top: 5px;
                                color: var(--blocks-blue);
                                cursor: pointer;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 650px) {
                    .timeline-section-items {
                        padding-left: 55px;
                    }
                }

                @media only screen and (max-width: 650px) {
                    .timeline-section-items {

                        .section-item {
    
                            .item-header {
                                display: flex;
                                align-items: flex-start;
                                flex-direction: column-reverse;

                                h3 {
                                    margin-right: 0;
                                    margin-top: 3px;
                                }
                            }
                        }
                    }
                }

                .line-container {
                    position: absolute;

                    &.primary {
                        top: 29px;
                        left: 34px;
                        z-index: 4;

                        .connecting-line {
                            background-color: var(--blocks-blue);
                        }

                        .connecting-line:before, .connecting-line:after {
                            background: var(--blocks-blue); 
                        }
                    }

                    &.future {
                        z-index: 3;
                        left: 34px;
                        top: 0;

                        .connecting-line {
                            background-color: var(--roadmap-future-pill);
                        }

                        .connecting-line:before, .connecting-line:after {
                            background: var(--roadmap-future-pill);
                        }
                    }
                }

                .connecting-line {
                    width: 5px;
                    position: relative;
                }

                #primary-connecting-line {
                    height: 0;
                }

                .connecting-line:before {
                    position: absolute; 
                    left: -10px; 
                    top: -5px; 
                    height: 25px; 
                    width: 25px; 
                    background: var(--blocks-blue); 
                    content: ""; 
                    border-radius: 50px;
                }

                .connecting-line:after {
                    position: absolute; 
                    right: -10px; 
                    bottom: -5px; 
                    height: 25px; 
                    width: 25px; 
                    background: var(--blocks-blue); 
                    content: ""; 
                    border-radius: 50px;
                }
            }
        }
    }

    .share-idea-popup::part(panel) {
        border-radius: 25px;
        margin: 15px;
    }

    .share-idea-popup::part(overlay) {
        backdrop-filter: blur(4px);
    }

    .share-idea-popup::part(body) {
        display: flex;
        flex-direction: column;
    }

    .share-idea-popup {

        .form-container {
            display: flex;
            flex-direction: column;

            label {
                font-size: 12pt;
                margin-bottom: 8px;
                color: var(--text-color-secondary);
            }
    
            input, sl-select {
                margin-bottom: 25px;
            }
    
            textarea {
                height: 150px;
            }
        }
        
        .success-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 150px;
            animation: fade-in-success 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation-delay: 0.5s;

            @keyframes fade-in-success {
                0% {
                    will-change: filter;
                    transform: translateY(10px);
                    filter: blur(5px);
                    opacity: 0;
                }
                100% {
                    will-change: filter;
                    transform: translateY(0);
                    filter: blur(0px);
                    opacity: 1;
                }
            }

            svg {
                font-size: 32pt;
                color: var(--blocks-blue);
                margin-bottom: 10px;
            }

            h4 {
                font-size: 18pt;
                font-weight: 500;
                text-align: center;
            }
        }

        .share-idea-header {
            font-size: 22pt;
            font-weight: 600;
            color: var(--blocks-blue);
        }

        .share-button {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
        }
    }
}