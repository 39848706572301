#blocks-badge {
    background-color: lightgray;
    padding: 5px 13px;
    font-size: 10pt;
    border-radius: 10px;
    font-weight: 600;
    white-space: nowrap;
    cursor: default;

    &.new {
        color: var(--button-text-color);
        background-color: var(--blocks-blue);
    }

    &.coming-soon {
        color: var(--text-color-main);
        background-color: var(--coming-soon-badge);
    }

    &.feature {
        color: var(--button-text-color);
        background-color: var(--feature-badge);
    }
}