.app-card {
    display: flex;
    align-items: center;
    max-width: 430px;

    .app-icon {
        height: 115px;
        border-radius: 25px;

        &.white {
            box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.15);
        }
    }

    .app-body {
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        .app-title {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            h3 {
                font-size: 18pt;
                font-weight: 600;
                margin-right: 10px;
            }
        }

        .app-description {
            font-size: 12pt;
            margin: 5px 0;
            color: var(--text-color-secondary);
        }

        .app-actions {
            display: flex;
            align-items: center;

            span {
                display: flex;
                align-items: center;
            }

            a {
                margin-right: 12px;
                text-decoration: none;
                color: var(--blocks-blue);
                font-size: 12pt;
            }

            .vertical-rule {
                height: 20px;
                width: 1.5px;
                border-radius: 10px;
                background-color: var(--divider-light); 
                margin-right: 12px;
                margin-top: 0px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .app-card {
        .app-icon {
            height: 85px;
            border-radius: 20px;
        }

        .app-body {
            padding-left: 12px;
    
            .app-title {
    
                h3 {
                    font-size: 14pt;
                }
            }
    
            .app-description {
                font-size: 10pt;
            }
    
            .app-actions {
                flex-wrap: wrap;
    
                a {
                    margin-right: 6px;
                    text-decoration: none;
                    color: var(--blocks-blue);
                    font-size: 10pt;
                    white-space: nowrap;
                }
    
                .vertical-rule {
                    margin-right: 6px;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .app-card {
        flex-direction: column;
        align-items: flex-start;
        .app-icon {
            height: 55px;
            border-radius: 15px;
        }

        .app-body {
            padding-left: 0px;
            padding-top: 6px;
    
            .app-title {
    
                h3 {
                    font-size: 14pt;
                }
            }
    
            .app-description {
                font-size: 10pt;
            }
    
            .app-actions {
                flex-wrap: wrap;
    
                a {
                    margin-right: 6px;
                    text-decoration: none;
                    color: var(--blocks-blue);
                    font-size: 10pt;
                    white-space: nowrap;
                }
            }
        }
    }
}