@media only screen and (max-width: 750px) {

    .desktop-menu {
        display: none;
    }
  
    .mobile-menu {
      display: flex;
  
        .mobile-header {
            display: flex;
            align-items: center;
        }
  
        .menu-toggle {
            font-size: 20pt;
            cursor: pointer;
        }
  
        .mobile-overlay {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 950;
            height: 100vh;
            width: 100vw;
            background-color: var(--modal-overlay-color);
            backdrop-filter: blur(4px);
            visibility: visible;
            transition: background-color 0.6s ease, backdrop-filter 0.6s ease;
        }
  
        .menu-container {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 955;
            background-color: var(--background-color);
            height: 100vh;
            width: 80vw;
            max-width: 225px;
            transition: right 0.3s ease;
  
            display: flex;
            flex-direction: column;
  
            .close {
                font-size: 24pt;
                
                svg {
                    padding: 25px;
                    cursor: pointer;
                }
            }
  
            nav {
                list-style: none;
                text-align: left;
                padding: 0;
                padding-left: 25px;

                #hidden {
                    display: none;
                }


                a {
                    color: var(--text-color-main);
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    transition: color 0.25s ease;
                    padding: 15px 0;

                    svg {
                        font-size: 24pt;
                        margin-right: 10px;
                    }

                    body.hasHover &:hover {
                        color: var(--text-color-secondary);
                    }

                    &.active {
                        color: var(--blocks-blue);

                        body.hasHover &:hover {
                            color: var(--blocks-blue);
                        }
                    }
                }
            }
        }
  
        #overlay-hidden {
            background-color: #ffffff00;
            backdrop-filter: blur(0px);
            visibility: hidden;
        }
  
        #container-hidden {
            right: -350px;
        }
    }
  
  }
  
@media only screen and (min-width: 751px) {
    .mobile-menu {
        display: none;
    }
}
  
.desktop-menu {    
        
    nav {
        display: flex;
        list-style: none;
        align-items: center;

        a {
            color: var(--text-color-main);
            transition: color 0.3s ease;
            text-decoration: none;
            margin-left: 30px;

            body.hasHover &:hover {
                color: var(--text-color-secondary);
            }

            &.active {
                color: var(--blocks-blue);
                font-weight: 500;

                body.hasHover &:hover {
                    color: var(--blocks-blue);
                }
            }
        }
    }
}