.email-capture {
    background: rgb(255, 255, 255);
    background-image: radial-gradient(
        circle at top left,
        #f1f7ff,
        #cae1fe 70%
      );;
    // background: linear-gradient(190deg, #cae1fe 0%, #f1f7ff 100%);
    width: calc(100vw - 100px);
    padding: 200px 50px;
    margin-left: -25px;
    display: flex;
    align-items: center;
    justify-content: center;

    .contact-block {

        h1 {
            font-size: 28pt;
            font-weight: 500;
            color: #1084ffc4;
        }

        .input-wrapper {
            margin-top: 25px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
        }

        #email-input {
            color: #4d4d4d;
            width: 80%;
            max-width: 400px;
        }

        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #a8a8a8;
            opacity: 1; /* Firefox */
          }
          
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #a8a8a8;
          }
          
          ::-ms-input-placeholder { /* Microsoft Edge */
            color: #a8a8a8;
          }

        .mobile-arrow {
            display: none;
        }

        .mobile-spinner {
            display: none !important;
        }

        button {
            margin-left: 12px;
        }

        .support-text {
            font-weight: 400;
            color: #1084ffc4;
            padding-left: 16px;
        }

        #error {
            color: #bb0000;
        }
          

        .sign-up-success {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            color: var(--button-text-color);
            font-size: 24pt;
            font-weight: 300;
            margin-top: 30px;
            margin-bottom: 5px;

            svg {
                margin-bottom: 10px;
            }
        }

        .fade-in-fwd {
            animation: fade-in-fwd 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        }

        @keyframes fade-in-fwd {
            0% {
              -webkit-transform: translateZ(-80px);
                      transform: translateZ(-80px);
              opacity: 0;
            }
            100% {
              -webkit-transform: translateZ(0);
                      transform: translateZ(0);
              opacity: 1;
            }
        }

        .scale-in-center {
            animation: scale-in-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation-delay: 0.25s;
        }

        @keyframes scale-in-center {
            0% {
              -webkit-transform: scale(0);
                      transform: scale(0);
              opacity: 1;
            }
            100% {
              -webkit-transform: scale(1);
                      transform: scale(1);
              opacity: 1;
            }
        }  
    }
}

@media only screen and (max-width: 850px) {
    .email-capture {
        .contact-block {
            h1 {
                font-size: 24pt;
            }
    
            .input-wrapper {
                input {
                    width: 50%;
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .email-capture {
        width: calc(100vw - 50px);


        .contact-block {
            h1 {
                font-size: 24pt;
            }
    
            .input-wrapper {
                display: flex;
                align-items: center;
                input {
                    width: 80%;
                    padding-right: 55px;
                }
                .mobile-arrow {
                    display: block;
                    font-size: 24pt;
                    margin-left: -60px;
                    color: var(--blocks-blue);
                    cursor: pointer;
                }
                button {
                    display: none;
                }
                .mobile-spinner {
                    display: flex !important;
                    margin-left: -65px;
                }
            }
        }
    }
}