.tags-container {
    display: flex;
    align-items: center;

    .tile-fade {
        align-self: flex-end;
        background-image: linear-gradient(to right, rgba(255, 255, 255,0), rgba(255, 255, 255,1));
        width: 75px;
        height: 70px;
        position: absolute;
        right: 20px;
    }
}

.tags::-webkit-scrollbar {
    display: none;
}

.tags {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: 10px;

    .tag-tile {
        background-color: var(--container-bg-color);
        color: var(--text-color-main);
        padding: 10px 20px;
        border-radius: 10px;
        white-space: nowrap;
        width: fit-content;
        height: fit-content;
        margin-right: 10px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        transition: background-color 0.4s ease;

        body.hasHover &:hover {
            background-color: var(--blocks-blue);
            color: var(--button-text-color);
        }
    }

    .spacer {
        color: transparent;
        white-space: nowrap;
    }
}

.fade-in-right {
    animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes fade-in-right {
0% {
    transform: translateX(50px);
    opacity: 0;
}
100% {
    transform: translateX(0);
    opacity: 1;
}
}