#resources {
    .resources-tagline {
        margin: 50px 0 75px 0;
        animation: resources-focus-in 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation-delay: 0.2s;

        @keyframes resources-focus-in {
            0% {
                will-change: filter;
                filter: blur(7px);
                transform: scale(0.95) translateY(30px);
                opacity: 0;
            }
            100% {
                will-change: filter;
                filter: blur(0px);
                transform: scale(1) translateY(0px);
                opacity: 1;
            }
        }

        h1 {
            font-size: min(60pt, 6.5vw);
            color: var(--blocks-blue);
            font-weight: 600;
            text-align: center;

            &.mobile {
                font-size: min(60pt, 10.5vw);
            }

            &.tagline-split {
                margin-bottom: 25px;
            }
        }

    }

    @media only screen and (max-width: 550px) {
        .resources-tagline {
            margin: 20px 0 40px 0;
        }
    }

    .resource-content-container {
        height: fit-content;
        min-height: 600px;
        margin-left: 325px;
        margin-bottom: 75px;

        .page-title {
            font-size: 26pt;
            padding-top: 22px;
        }

        .resource-app-grid {
            margin: 25px 0;
            display: grid;
            grid-template-columns: 50% 50%;
            width: calc(100% - 25px);
            max-width: 885px;
            column-gap: 25px;
            row-gap: 25px;
        }
    }

    @media only screen and (max-width: 1300px) {
        .resource-content-container {
            height: fit-content;
            min-height: 575px;
            margin-left: 265px;
        }
    }

    @media only screen and (max-width: 1200px) {
        .resource-content-container {
            .resource-app-grid {
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                row-gap: 25px;
            }
        }
    }

    @media only screen and (max-width: 750px) {
        .resource-content-container {
            height: fit-content;
            min-height: 525px;
            margin-left: 55px;
            margin-bottom: 50px;
            transform: translateX(0px);
            transition: transform 0.3s ease;

            .page-title {
                font-size: 22pt;
                padding-top: 15px;
            }

            &.with-expanded-sidebar {
                transform: translateX(170px);
            }
        }
    }
}